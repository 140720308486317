import React, { Component } from "react";
import {NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import { BsFillLayersFill } from "react-icons/bs";
class LeftPanel extends Component {
    state = {}
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <>
            <nav
                className="menu"
                style={{
                    border: "none",
                    display: "flex",
                    listStyle: "none",
                    height: "80px",
                    justifyContent : 'space-between',
                    overflowY: 'auto'
                }}
            >
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "1",
                    })}
                >
                    <NavLink
                        id="imageTabMobile"
                        className={classnames({
                            active: this.props.activeTab === "1",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "1") {
                                this.props.toggle("1");
                            } 
                            this.setState({ isMasking: false });
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <i className="fas fa-image fa-2x text-muted "></i>
                            </div>
                            <div className="side-nav">Images</div>
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "2",
                    })}
                >
                    <NavLink
                        className={classnames({
                            active: this.props.activeTab === "2",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "2") {
                                this.props.toggle("2");
                            } else {
                                this.props.toggle("");
                            }
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <i className="fas fa-font fa-2x text-muted"></i>
                            </div>
                            <div className="side-nav">Text</div>
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "3",
                    })}
                >
                    <NavLink
                        id="imageTabMobile"
                        className={classnames({
                            active: this.props.activeTab === "3",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "3") {
                                this.props.toggle("3");
                            }
                            this.setState({ isMasking: false });
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <i className="fas fa-palette fa-2x text-muted"></i>
                            </div>
                            <div className="side-nav">Color</div>
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "4",
                    })}
                >
                    <NavLink
                        id="imageTabMobile"
                        className={classnames({
                            active: this.props.activeTab === "4",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "4") {
                                this.props.toggle("4");
                            }
                            this.setState({ isMasking: false });
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <i className="fas fa-images fa-2x text-muted"></i>
                            </div>
                            <div className="side-nav">Clip Art</div>
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "5",
                    })}
                >
                    <NavLink
                        id="imageTabMobile"
                        className={classnames({
                            active: this.props.activeTab === "5",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "5") {
                                this.props.toggle("5");
                            }
                            this.setState({ isMasking: false });
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <i className="fas fa-shapes fa-2x text-muted"></i>
                            </div>
                            <div className="side-nav">Shapes</div>
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem
                    className={classnames({
                        selectedColor: this.props.activeTab === "6",
                    })}
                >
                    <NavLink
                        id="imageTabMobile"
                        className={classnames({
                            active: this.props.activeTab === "6",
                        })}
                        onClick={() => {
                            if (this.props.activeTab !== "6") {
                                this.props.toggle("6");
                            }
                            this.setState({ isMasking: false });
                        }}
                    >
                        <span className="">
                            <div className="d-flex justify-content-center">
                                <BsFillLayersFill className="layers-icon"/>
                            </div>
                            <div className="side-nav">Layers</div>
                        </span>
                    </NavLink>
                </NavItem>
            </nav>
            </>
        )
    }
}

export default LeftPanel;