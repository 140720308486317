import { fabric } from 'fabric';
import {DPI} from '../constants'
import { removeStates,removeStates2,addToHistory,addToHistory2 } from './CanvasHistory';

//Function to remove duplicate items from the array.
export function unique(dupArray) {
    return dupArray.reduce(function (previous, num) {
        if (previous.find(function (item) {
            return item === num;
        })) {
            return previous;
        }
        else {
            previous.push(num);
            return previous;
        }
    }, []);
}

//Function to get offset left and top of the DOM element.
export function getOffset(el) {
    el = document.getElementById(el);
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}

//Function to save canvas history for undo / redo.
export function saveCanvasState(canvas) {
    if (canvas.stateaction && canvas.state) {
        var newstate = [];
        var index = canvas.index;
        var state = canvas.state;
        for (var i = 0; i <= index; i++) {
            newstate.push(state[i]);
        }
        state = newstate;
        var myjson = JSON.stringify(canvas);
        state[++index] = myjson;
        if (state.length >= 80) state = state.splice(-5, 5);
        canvas.state = state;
        canvas.index = index;
    }
}

/**
 * Augments canvas by assigning to `onObjectMove` and `onAfterRender`.
 * This kind of sucks because other code using those methods will stop functioning.
 * Need to fix it by replacing callbacks with pub/sub kind of subscription model.
 * (or maybe use existing fabric.util.fire/observe (if it won't be too slow))
 */
export function initCenteringGuidelines(canvas) {
    var canvasWidth = canvas.getWidth(),
        canvasHeight = canvas.getHeight(),
        canvasWidthCenter = canvasWidth / 2,
        canvasHeightCenter = canvasHeight / 2,
        canvasWidthCenterMap = {},
        canvasHeightCenterMap = {},
        centerLineMargin = 4
    for (var i = canvasWidthCenter - centerLineMargin, len = canvasWidthCenter + centerLineMargin; i <= len; i++) {
        canvasWidthCenterMap[Math.round(i)] = true;
    }
    for (var j = canvasHeightCenter - centerLineMargin, lens = canvasHeightCenter + centerLineMargin; j <= lens; j++) {
        canvasHeightCenterMap[Math.round(j)] = true;
    }
}

export function pixelSize(sizeInInch){
    return parseFloat(sizeInInch)*DPI
}

export const getCanvasBySide= (allCanvasList,side )=>{
    const item = allCanvasList?.find(item =>item.side === side)
    return item?.canvas
}

export const deleteCanvasBg = (canvas) => {
    if (canvas) {
        var objects = canvas.getObjects().filter(function (o) {
            return o.bg === true;
        });
        for (var i = 0; i < objects.length; i++) {
            canvas.remove(objects[i]);
        }
        canvas.bgsrc = "";
        canvas.bgcolor = "";
    }
};

export const setcanvasBG = (result,allCanvasList) => {
    deleteCanvasBg();
    const front = process.env.PUBLIC_URL + result.front;
    const back = process.env.PUBLIC_URL + result.back;
    fabric.Image.fromURL(front, (bg) => {
        const frontCanvas = getCanvasBySide(
            allCanvasList,
            "front"
        );
        bg.set({top:0,left:0,scaleX:fabric.Canvas.originalWidth/bg.getScaledWidth(),
        scaleY:fabric.Canvas.originalHeight/ bg.height,name:"frontBg"})
        frontCanvas.setBackgroundImage(bg)
        frontCanvas.renderAll()
        saveCanvasState(frontCanvas);
        removeStates() 
        addToHistory(frontCanvas)
    });
    fabric.Image.fromURL(back, (bg) => {
        const backCanvas = getCanvasBySide(
            allCanvasList,
            "back"
        );
        bg.set({top:0,left:0,scaleX:fabric.Canvas.originalWidth/ bg.getScaledWidth(),
        scaleY: fabric.Canvas.originalHeight / bg.height,name:"backBg"})
        backCanvas.setBackgroundImage(bg)
        backCanvas.renderAll()
        saveCanvasState(backCanvas);
        removeStates2()
        addToHistory2(backCanvas)
    });
}

export const getDpi = (imageWidth, printWidth) => {
    if(!parseFloat(imageWidth) || !parseFloat(printWidth)) {
        return 0
    }
    return Math.round(parseFloat(imageWidth) / parseFloat(printWidth));
}