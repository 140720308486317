import { PROPS_TO_INCLUDE_IN_CANVAS } from "../constants/index"
let CANVAS_STATES = [];
let CANVAS_STATES2 = [];
let CANVAS_STATE_INDEX = 0;
let CANVAS_STATE_INDEX2 = 0;

const addToHistory = async (newCan,zoom) => {
    const canvasJSON = newCan?.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
    const zoomLevel = newCan?.getZoom();
    if (canvasJSON && zoomLevel !== undefined) {
        canvasJSON.zoom = zoomLevel; // Add zoom level to canvas JSON
        canvasJSON.objects = canvasJSON.objects.filter(element => element.name !== 'selectionRect');
        delete canvasJSON.overlayImage;
        // Store information about the active object
        const activeObject = newCan?.getActiveObject();
        if (activeObject) {
        canvasJSON.activeObject = {
            id: activeObject.id, // Assuming your objects have unique IDs
        };
        }
        CANVAS_STATES.push(canvasJSON);
        CANVAS_STATE_INDEX = CANVAS_STATES.length - 1;
    }
};

const addToHistory2 = async (newCan) => {
    const canvasJSON = newCan?.toJSON(PROPS_TO_INCLUDE_IN_CANVAS);
    const zoomLevel = newCan?.getZoom();
    if (canvasJSON && zoomLevel !== undefined) {
        canvasJSON.zoom = zoomLevel; // Add zoom level to canvas JSON
        CANVAS_STATES2.push(canvasJSON);
        CANVAS_STATE_INDEX2 = CANVAS_STATES2.length - 1;
    }
}

const addStateIndex = () => {
    if (CANVAS_STATE_INDEX > 0) {
        CANVAS_STATE_INDEX = CANVAS_STATE_INDEX - 1
    }
    return CANVAS_STATE_INDEX
}

const addStateIndex2 = () => {
    if (CANVAS_STATE_INDEX2 >= 0) {
        CANVAS_STATE_INDEX2 = CANVAS_STATE_INDEX2 - 1
    }
    return CANVAS_STATE_INDEX2
}

const removeStateIndex = () => {
    if (CANVAS_STATE_INDEX < CANVAS_STATES.length - 1) {
        CANVAS_STATE_INDEX = CANVAS_STATE_INDEX + 1;
    }
    return CANVAS_STATE_INDEX
}

const removeStateIndex2 = () => {
    if (CANVAS_STATE_INDEX2 <= CANVAS_STATES2.length - 1) {
        CANVAS_STATE_INDEX2 = CANVAS_STATE_INDEX2 + 1;
    }
    return CANVAS_STATE_INDEX2
}

const resetHistory = () => {
    while (CANVAS_STATES.length > 0) {
        CANVAS_STATES.pop()
    }
}

const removeStates = () => {
    CANVAS_STATES = CANVAS_STATES.filter((ele, index) => {
        return index <= CANVAS_STATE_INDEX
    })
}

const removeStates2 = () => {
    CANVAS_STATES2 = CANVAS_STATES2.filter((ele, index) => {
        return index <= CANVAS_STATE_INDEX2
    })
}

export { CANVAS_STATES, CANVAS_STATE_INDEX, CANVAS_STATES2, CANVAS_STATE_INDEX2, addToHistory, addToHistory2, addStateIndex, addStateIndex2, removeStateIndex, removeStateIndex2, resetHistory, removeStates, removeStates2 }